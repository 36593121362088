import axios from '../http'

export function getKey(data) {
    return axios.get('userKey', data)
}

export function addKey(data) {
    return axios.post('userKey', data)
}

export function delKey(data) {
    return axios.delete('userKey/'+data.id, data)
}

