<template>
  <div class="page">
    <!--顶部首页-->
    <div class="top-title">
      <div>
<!--        <span>首页</span>-->
      </div>
    </div>
    <!--轮播图-->
    <div class="rotation-picture">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(b,index) in banners" :key="index" @click="bannerGo(index)">
          <img style="height: 175px; width: 100%" :src="b.img" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="quickly">
      <div class="item" @click="go('select')">
        <img src="@/assets/index/q1.png" />
        <div>用户注册</div>
      </div>
      <div class="item" @click="go('key-word')">
        <img src="@/assets/index/q2.png" />
        <div>订阅招工</div>
      </div>
      <div class="item" @click="go('yellow')">
        <img src="@/assets/index/q3.png" />
        <div>起重人黄页</div>
      </div>
      <div class="item" @click="go('zhaopin')">
        <img src="@/assets/index/q4.png" />
        <div>工厂招聘</div>
      </div>
      <div class="item" @click="showQrcode=true">
        <img src="@/assets/index/q5.png" />
        <div>联系客服</div>
      </div>
    </div>
    <div class="box">
      <!--定制商机-->
      <div class="customized-box">
<!--        <div>-->
<!--          <h5>-->
<!--            <span>定制商机</span>-->
<!--            <span>添加商机先人一步</span>-->
<!--          </h5>-->
<!--          <div class="customized">-->
<!--            <template>-->
<!--              <van-tag size="medium" plain round type="primary" v-for="k in keys" :key="k.id">{{ k.word }}</van-tag>-->
<!--            </template>-->
<!--            <van-tag size="medium" round type="primary" @click="showAddKey=true">添加+</van-tag>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <!--精选商机-->
      <div class="selected-box">
        <h5>
          <span style="">为你推荐</span>
<!--          <van-button type="warning" class="add-info" @click="go('select')">-->
<!--            <div class="add-info-lr">-->
<!--              <div>-->
<!--                <van-icon name="edit" />-->
<!--              </div>-->
<!--              <div>完善信息 快人一步</div>-->
<!--            </div>-->
<!--          </van-button>-->
        </h5>
        <van-swipe class="selected-content" :show-indicators="false" :width="300">
          <van-swipe-item class="tj-item" v-if="keyDemands.length" v-for="item in keyDemands" :key="item.id">
              <div class="front-date">
                <div>{{new Date(item.createdDate).getDate()+1}}</div>
                <div>{{new Date(item.createdDate).getMonth()+1}}月</div>
              </div>
              <div class="business-info">
                <div class="info-title">{{item.title}}</div>
                <div class="info-phone">
                  <div>{{ item.contactsName.substring(0,1) }}** </div>
                  <div style="width: 50%">
                    <!-- <div >
                                         <van-progress style="margin-top: 5%;" :percentage="33.3" stroke-width="1"  />
                                     </div>-->
                    <div>{{ item.contactsTel }}</div>
                  </div>
                </div>
                <div class="info-content" style="">
                  {{item.content}}
                </div>
                <div class="info-bottom">
                  <div class="money" style="">{{item.type=='RECRUITMENT'?'[招工]':'[需求]'}}</div>
                  <van-button type="info" class="btn" @click="go('/demand/'+item.id)"> 查看详情 </van-button>
                </div>
              </div>
<!--              <div class="after-date">-->
<!--                <div>27</div>-->
<!--                <div style="">9月</div>-->
<!--              </div>-->
          </van-swipe-item>
          <van-swipe-item v-if="!keyDemands.length">
<!--            <div style="text-align: center;line-height: 20px;padding-left: 50px;margin-top: 40px;">-->
<!--              <div>定制专属于您的招工信息</div>-->
<!--              <div style="margin-left:100px;margin-top:3px;padding: 3px 5px;border: 1px solid #1989fa;color:#1989fa;width: 46px;border-radius: 3px;" @click="go('/key-word')">去定制</div>-->
<!--            </div>-->
            <img src="@/assets/index/dingyue.png" style="height: 150px; width: 135%;margin-top:-12px;margin-left:-8px;" @click="go('/key-word')"/>
          </van-swipe-item>
        </van-swipe>
<!--        <div class="selected-content">-->
<!--          <div class="front-date">-->
<!--            <div>28</div>-->
<!--            <div>9月</div>-->
<!--          </div>-->
<!--          <div class="business-info">-->
<!--            <div class="info-title">齐鲁软件园急求6名外墙粉刷高空作业人员</div>-->
<!--            <div class="info-phone">-->
<!--              <div>18865985645</div>-->
<!--              <div style="width: 60%">-->
<!--                &lt;!&ndash; <div >-->
<!--                                     <van-progress style="margin-top: 5%;" :percentage="33.3" stroke-width="1"  />-->
<!--                                 </div>&ndash;&gt;-->
<!--                <div>2/6</div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="info-content" style="">-->
<!--              要求干活实在，有实力的人，项目稳定，资金-->
<!--              没问题，包吃住，地点在济南高新区经十东山东济南经十东经十东路经十东路-->
<!--            </div>-->
<!--            <div class="info-bottom">-->
<!--              <div class="money" style="">300元/日</div>-->
<!--              <van-button type="info" class="btn"> 查看详情 </van-button>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="after-date">-->
<!--            <div>27</div>-->
<!--            <div style="">9月</div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <!--商机大厅-->
      <div class="hall">
        <div class="top">
          <h5>
            <span>招工大厅</span>
<!--            <van-icon name="arrow" />-->
          </h5>
<!--          <div class="hall-box">-->
<!--            <div class="shuidian-box">-->
<!--              <div class="shuidian-money">300+</div>-->
<!--              <div class="shuidiangong">水电工</div>-->
<!--            </div>-->
<!--            <div class="wajueji-box">-->
<!--              <div class="wajueji-money">690+</div>-->
<!--              <div class="wajueji">挖掘机</div>-->
<!--            </div>-->
<!--            <div class="jioabanche-box">-->
<!--              <div class="jioabanche-money">500+</div>-->
<!--              <div class="jioabanche">搅拌车</div>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <div class="hall-content" v-for="item in demands" :key="item.id">
          <div class="worker-photo" style="">
<!--            <img src="../../assets/images/qizhongji.png" alt="" />-->
            <img class="createdUserAvatar" style="border-radius: 50%;" v-if="item.type=='RECRUITMENT'" src="@/assets/images/zg.jpg" alt="" />
            <img class="createdUserAvatar" v-else src="@/assets/images/xq.jpg" style="border-radius: 50%;" alt="" />

          </div>
          <div class="con-right">
            <div class="con-top">
              <div class="con-title" v-text="item.title">
                齐鲁软件园急求6名外墙粉刷高空作业人员
              </div>
<!--              <div class="progress">12/16</div>-->
              <div class="progress" v-text="item.createdDate.split(' ')[0]">12/16</div>
            </div>
            <div class="address">
              <div>工作地点: {{ item.workingPlaceName }}</div>
<!--              <div v-text="item.createdDate.split(' ')[0]">2021.10.26</div>-->
            </div>
            <div class="con-bottom">
              <div class="con-money" v-text="item.remuneration">300元/日</div>
              <van-button type="info" class="con-btn" @click="go('/demand/'+item.id)"> 查看详情 </van-button>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 80px"></div>
<!--      <div class="around-button" @click="go('demand')" v-if="user.role!='CUSTOM'">-->
<!--&lt;!&ndash;        <van-icon name="guide-o" />&ndash;&gt;-->
<!--        <span style="font-size:18px">发布</span>-->
<!--      </div>-->

<!--      <div class="around-button" @click="goAdd()">-->
<!--        <span style="font-size:18px">发布</span>-->
<!--      </div>-->

      <!--TODO 待处理页面位置-->
      <!-- <van-button type="warning" class="add-info" @click="goFaBu"
        >发布招工</van-button
      >
       <van-button
        type="warning"
        class="add-info"
        @click="go(`demand`)"
        >发布需求</van-button
      >
      <van-button
        type="warning"
        class="add-info"
        @click="go(`demand-list`)"
        >需求列表</van-button
      > -->
    </div>
    <van-dialog v-model="showQrcode" title="联系客服" style="text-align: center">
      <div>客服微信号：602581887</div>
      <div>长按下面二维码添加</div>
      <img width="280px" src="@/assets/images/kf.jpg" />
    </van-dialog>
    <van-dialog v-model="showAddKey" title="添加订阅词" show-cancel-button @confirm="addKey" @cancel="showAddKey=false">
      <van-field v-model="keyData.word" placeholder="请输入订阅词" />
    </van-dialog>
  </div>
</template>

<script>
import * as demandApi from "../../api/demand/index";
import {getBanner} from "../../api/banner";
import {getKey,addKey} from "../../api/userKey";
import store from '../../store'
import { Dialog,Toast } from 'vant';
export default {
  data() {
    return {
      showQrcode:false,
      user:{},
      demands: [],
      keyDemands:[],
      isDemandLast: true,
      banners:[],
      keys:[],
      keyData:{
        word:''
      },
      showAddKey: false,
    };
  },
  mounted() {
    this.loadData();
    this.loadBanner();
    this.loadKey();
    this.loadKeyData();
    this.user = JSON.parse(`${store.state.user}`)
    console.info("index,user:",this.user)
  },
  methods: {
    bannerGo(index){
      let banner = this.banners[index]
      if (banner.url){
        this.$router.push(banner.url);
      }
    },
    async loadData() {
      let r = await demandApi.findAll();
      this.demands = r.content;
      this.isDemandLast = r.last;
    },
    async loadBanner() {
      let r = await getBanner();
      this.banners = r.data;
      console.info("banners",this.banners)
    },
    async loadKey() {
      let r = await getKey();
      this.keys = r.data.content;
      console.info("loadKey",this.keys)
    },
    async loadKeyData() {
      let r = await demandApi.findByKey(this.params);
      console.info("loadKeyData",r)
      if (r){
        this.keyDemands = r.content;
      }

      // this.isDemandLast = r.last;
    },
    async addKey() {
      if(!this.keyData.word){
        Toast.fail("请填写订阅词");
        return;
      }
      let r = await addKey(this.keyData);
      this.loadKey()
      this.showAddKey=false;
      this.keyData.word=''
      Toast.success('添加成功');
    },
    go(url) {
      this.$router.push(url);
    },
    goAdd(){
      if (this.user.cardNo){
        this.$router.push("demand");
      }else{
        this.$router.push("my-authentication");
      }
    },
    goAuth(){
      if (this.user.phone){
        this.$router.push("demand");
      }else{
        this.$router.push("my-authentication");
      }
    }
  },
  computed: {},
};
</script>

<style lang="less" scoped>
body {
  background: #f6f6f6;
}
.createdUserAvatar{
  border-radius: 3px;
}
//.top-title {
//  height: 120px;
//  background: #1083fc;
//  color: #ffffff;
//  div {
//    display: flex;
//    flex-direction: column;
//    align-items: flex-start;
//    span {
//      margin: auto;
//      padding-top: 5px;
//      font-size: 16px;
//    }
//  }
//}

.quickly{
  padding: 12px 5px;
  //margin-top: 20px;
  height: 80px;
  background: #fafafa;
  display: flex;
  justify-content: space-between;
  .item{
    width: 70px;
    height: 70px;
    text-align: center;
    img{
      width: 80%;
      height: 80%;
      border-radius: 60px;
    }
    div{
      font-size: 13px;
      line-height: -10px;
    }
  }
}

.rotation-picture {
  max-width: 700px;
  border-radius: 7px;
  width: 100%;
  margin: 0;
  height: 175px;
  background: #ffffff;
  //position: absolute;
  //left: 50%;
  //transform: translate(-50%, -125px);
}

.box {
  //border-radius: 20px;
  background: #fafafa;
  height: 750px;
  margin-top: -23px;
  padding: 10px;
  padding-top: 0px;

  .customized-box {
    background: #ffff;
    //margin-top: 10px;

    h5 {
      border-left: 3px solid #286efc;

      :first-child {
        margin-left: 10px;
      }

      :last-child {
        margin-left: 12px;
        font-weight: 500;
        color: #8b8d90;
        font-size: 10px;
      }
    }
  }

  .customized {
    background: #ffffff;
    height: 26px;
    display: flex;
    justify-content: flex-start;
    padding: 8px;
    padding-top: 0px;
    padding-bottom: 10px;
    span{
      margin-right: 3px;
    }
    .qizhongji {
      background: #f7f7f7;
      height: 70px;
      width: 50px;
      text-align: center;

      img {
        height: 60px;
        width: 45px;
      }

      div {
        font-size: 12px;
        margin-top: 10px;
      }
    }

    .tuituji {
      background: #f7f7f7;
      height: 70px;
      width: 50px;
      text-align: center;
      margin-left: 5px;

      img {
        height: 60px;
        width: 45px;
      }

      div {
        font-size: 12px;
        margin-top: 10px;
      }
    }

    .add-business {
      background: #f7f7f7;
      height: 70px;
      width: 55px;
      text-align: center;
      margin-left: 5px;

      img {
        height: 60px;
        width: 45px;
      }

      div {
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }

  .selected-box {
    h5 {
      border-left: 3px solid #286efc;
      font-size: 18px;
      margin-block-start: 1.3em;
      margin-block-end: 1.3em;
    }

    span {
      margin-left: 10px;
    }

    .add-info {
      width: 100px;
      height: 35px;
      margin-right: 0;
      float: right;
      border-top-left-radius: 26px;
      border-bottom-left-radius: 26px;
      margin-top: -10px;
      .add-info-lr {
        display: flex;
        justify-content: space-between;

        :first-child {
          padding-top: 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        :last-child {
          font-size: 12px;
        }
      }
    }

    .selected-content {
      clear: both;
      background: #ffffff;
      height: 130px;
      display: flex;
      justify-content: space-between;
      padding: 8px;
      .tj-item{
        display: flex;
        justify-content: space-between;
        .front-date {
          background: #ffffff;
          height: 120px;
          width: 40px;
          text-align: center;
          margin-top: 15px;

          :first-child {
            font-size: 20px;
          }

          :last-child {
            font-size: 15px;
            color: #8b8d90;
          }
        }

        .business-info {
          background: #ffffff;
          height: 130px;
          width: 260px;
          text-align: center;
          padding-right: 10px;
          padding-right: 10px;
          .info-title {
            height: 22px;
            font-size: 16px;
            color: #212121;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
            line-height: 22px;
          }

          .info-phone {
            height: 22px;
            display: flex;
            justify-content: start;
            color: #4d4d4d;
            font-size: 15px;
            line-height: 22px;
          }

          .info-content {
            height: 58px;
            font-size: 14px;
            color: #8b8d90;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .info-bottom {
            height: 25px;
            display: flex;
            justify-content: space-between;
            padding-top: 8px;
            .money {
              color: #e7807d;
            }

            .btn {
              height: 22px;
              width: 100px;
              font-size: 12px;
              border-radius: 15px;
            }
          }
        }

        .after-date {
          background: #ffffff;
          height: 130px;
          width: 50px;
          text-align: center;
          margin-top: 15px;
          display:none;
          :first-child {
            font-size: 20px;
          }

          :last-child {
            font-size: 15px;
            color: #8b8d90;
          }
        }
      }


    }
  }

  .hall {
    background: #ffff;
    .top {

      h5 {
        border-left: 3px solid #286efc;
        font-size: 18px;
        margin-block-start: 1em;
        margin-block-end: 1.3em;
        //padding-top: 5px;
        span {
          margin-left: 10px;
        }
      }

      .hall-box {
        background: #ffffff;
        height: 40px;
        display: flex;
        justify-content: space-between;
        padding: 1px;

        .shuidian-box {
          background: #f3f7ff;
          height: 40px;
          width: 30%;
          text-align: center;
          padding: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .shuidian-money {
            height: 20px;
            width: 50px;
          }

          .shuidiangong {
            font-size: 12px;
            color: #bfc7d6;
          }
        }

        .wajueji-box {
          background: #f3f7ff;
          height: 40px;
          width: 30%;
          text-align: center;
          margin-left: 5px;
          padding: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .wajueji-money {
            height: 20px;
            width: 50px;
          }

          .wajueji {
            font-size: 12px;
            color: #bfc7d6;
          }
        }

        .jioabanche-box {
          background: #f3f7ff;
          height: 40px;
          width: 30%;
          text-align: center;
          margin-left: 5px;
          padding: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .jioabanche-money {
            height: 20px;
            width: 50px;
          }

          .jioabanche {
            font-size: 12px;
            color: #bfc7d6;
          }
        }
      }
    }

    .hall-content {
      background: #f3f7ff;
      height: 70px;
      display: flex;
      justify-content: space-between;
      padding: 8px;
      margin-top: 12px;
      align-items: center;
      .worker-photo {
        height: 60px;
        width: 60px;
        text-align: center;
        img {
          height: 60px;
          width: 60px;
        }
      }

      .con-right {
        height: 70px;
        width: 80%;
        padding: 5px;

        .con-top {
          display: flex;
          justify-content: space-between;

          .con-title {
            min-height: 22px;
            width: 68%;
            font-size: 18px;
            color: #212121;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .progress {
            font-size: 12px;
            color: #3b83ce;
            line-height: 22px;
          }
        }

        .address {
          min-height: 20px;
          font-size: 14px;
          color: #8b8d90;
          display: flex;
          justify-content: space-between;
        }

        .con-bottom {
          height: 30px;
          display: flex;
          justify-content: space-between;

          .con-money {
            color: #e7807d;
          }

          .con-btn {
            height: 22px;
            width: 80px;
            font-size: 12px;
            border-radius: 15px;
          }
        }
      }
    }
  }
}
.around-button {
  position: fixed;
  bottom: 100px;
  right: 30px;
  width: 50px;
  height: 50px;
  //font-size: 30px;
  line-height: 52px;
  text-align: center;
  background: #ff976a;
  border-radius: 50%;
  color: #fff;
}
</style>

<style>
.selected-box .add-info .van-button__content .van-button__text{
  margin-left: 0px;
}
</style>
